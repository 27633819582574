@import url("https://fonts.googleapis.com/css2?family=EB+Garamond&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@1,500&display=swap");
body {
  margin: 0;
  font-family: datz-medium, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  word-break: keep-all;
}
div {
  box-sizing: border-box;
}
input,
label,
select,
button,
textarea {
  margin: 0;
  border: 0;
  padding: 0;
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
  background: none;
  line-height: 1;
  color: inherit;
  /* Browsers have different default form fonts */
  font-size: 13px;
  font-family: datz-medium;
}
a {
  text-decoration: none;
  /* color: #707070; */
  color: inherit;
}
ul {
  display: flex;
  list-style-type: none;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
}
/* Remove the stupid outer glow in Webkit */
input:focus {
  outline: 0;
}

/* Box Sizing Reset
-----------------------------------------------*/

/* All of our custom controls should be what we expect them to be */
input,
textarea {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

/* These elements are usually rendered a certain way by the browser */
button,
input[type="reset"],
input[type="button"],
input[type="submit"],
input[type="checkbox"],
input[type="radio"],
select {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

/* Text Inputs
-----------------------------------------------*/

input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="range"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"] {
}

/* Button Controls
-----------------------------------------------*/

input[type="checkbox"],
input[type="radio"] {
  width: 13px;
  height: 13px;
}

/* File Uploads
-----------------------------------------------*/

input[type="file"] {
}

/* Search Input
-----------------------------------------------*/

/* Make webkit render the search input like a normal text field */
input[type="search"] {
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box;
}

/* Turn off the recent search for webkit. It adds about 15px padding on the left */
::-webkit-search-decoration {
  display: none;
}

/* Buttons
-----------------------------------------------*/

button,
input[type="reset"],
input[type="button"],
input[type="submit"] {
  /* Fix IE7 display bug */
  overflow: visible;
  width: auto;
  cursor: pointer;
}

/* IE8 and FF freak out if this rule is within another selector */
::-webkit-file-upload-button {
  padding: 0;
  border: 0;
  background: none;
}

/* Textarea
-----------------------------------------------*/

textarea {
  /* Move the label to the top */
  vertical-align: top;

  /* Turn off scroll bars in IE unless needed */
  overflow: auto;
}

/* Selects
-----------------------------------------------*/

p {
  margin: 0;
}

select[multiple] {
  /* Move the label to the top */
  vertical-align: top;
}
.image-gallery {
  height: 100%;
}
.image-gallery
  div:not(.image-gallery-bullets-container):not(.image-gallery-bullets),
.image-gallery img {
  height: inherit;
}
.image-gallery .image-gallery-bullets {
  height: auto;
  bottom: 30px;
}
.image-gallery .image-gallery-bullets .image-gallery-bullet.active {
  background-color: #707070;
}
.image-gallery .image-gallery-bullets-container .image-gallery-bullet {
  border: 1px solid #707070;
  box-shadow: none;
}
.image-gallery.white-bullets
  .image-gallery-bullets
  .image-gallery-bullet.active {
  background-color: white;
}
.image-gallery.white-bullets
  .image-gallery-bullets-container
  .image-gallery-bullet {
  border: 1px solid white;
}
.image-gallery.no-bullets .image-gallery-bullets-container {
  display: none;
}
.image-gallery
  .image-gallery-content
  .image-gallery-slide
  .image-gallery-image {
  max-height: none;
  object-fit: cover;
}
.contain-image {
  width: 100%;
  cursor: pointer;
}
.image-gallery.contain-image
  .image-gallery-content
  .image-gallery-slide
  .image-gallery-image {
  object-fit: contain;
}

@font-face {
  font-family: datz-regular;
  src: local(datz), url(./assets/fonts/datz/Datz-Regular.otf) format("opentype");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: datz-medium;
  src: local(datz), url(./assets/fonts/datz/Datz-Medium.otf) format("opentype");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

h1,
h2,
h3,
h4,
h5,
hr {
  margin: 0;
  font-weight: normal;
  border-bottom: 0;
}

.height ul.react-multi-carousel-track,
.height .react-multi-carousel-track div,
.height .react-multi-carousel-track img,
.height .react-multi-carousel-track li {
  height: 100%;
}

.width ul.react-multi-carousel-track,
.width .react-multi-carousel-track div,
.width .react-multi-carousel-track img,
.width .react-multi-carousel-track li {
  width: 100%;
}
/* 
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: #cccccc;
} 
*/

@media only screen and (min-width: 1000px) {
  .image-gallery-slide {
    transform: none !important;
    opacity: 0;
  }
  .image-gallery-slide.center {
    opacity: 1;
  }
}
.notranslate.public-DraftEditor-content span {
  word-break: keep-all;
  overflow-wrap: anywhere;
}
